/**
 * File generated by js-routes 2.2.4
 * Based on Rails 6.1.7.6 routes of Trees::Application
 */
const __jsr = (() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {"host":"betterplaceforests.com","subdomain":"trees"},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            let query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (key === "params") {
                    if (this.is_object(value)) {
                        query_parameters = {
                            ...query_parameters,
                            ...value,
                        };
                    }
                    else {
                        throw new Error("params value should always be an object");
                    }
                }
                else if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /api/v1/plan_invites/:url_slug/accept(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_plan_invite_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"plan_invites"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/plan_invites/:url_slug/accept(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_plan_invite_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"plan_invites"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/terms_of_service(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_wishes_tos_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"terms_of_service"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/terms_of_service(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_wishes_tos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"terms_of_service"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/promotions/:id/activate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activate_internal_promotion_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"promotions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"activate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/promotions/:id/activate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activate_internal_promotion_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"promotions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"activate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/amendments/:amendment_id/payments/:order_payment_id/thank_you(.:format)
 * @param {any} url_slug
 * @param {any} amendment_id
 * @param {any} order_payment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const amendment_order_payment_thank_you_url = __jsr.r({"url_slug":{"r":true},"amendment_id":{"r":true},"order_payment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"amendments"],[2,[7,"/"],[2,[3,"amendment_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[3,"order_payment_id"],[2,[7,"/"],[2,[6,"thank_you"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/amendments/:amendment_id/payments/:order_payment_id/thank_you(.:format)
 * @param {any} url_slug
 * @param {any} amendment_id
 * @param {any} order_payment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const amendment_order_payment_thank_you_path = __jsr.r({"url_slug":{"r":true},"amendment_id":{"r":true},"order_payment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"amendments"],[2,[7,"/"],[2,[3,"amendment_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[3,"order_payment_id"],[2,[7,"/"],[2,[6,"thank_you"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/amendments/:amendment_id/payments(.:format)
 * @param {any} url_slug
 * @param {any} amendment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const amendment_order_payments_url = __jsr.r({"url_slug":{"r":true},"amendment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"amendments"],[2,[7,"/"],[2,[3,"amendment_id"],[2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/amendments/:amendment_id/payments(.:format)
 * @param {any} url_slug
 * @param {any} amendment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const amendment_order_payments_path = __jsr.r({"url_slug":{"r":true},"amendment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"amendments"],[2,[7,"/"],[2,[3,"amendment_id"],[2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/collections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_collection_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/collections/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_collection_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/forests/:forest_id/collection(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_forest_collection_index_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"collection"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/forests/:forest_id/collection(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_forest_collection_index_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"collection"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/forests/:forest_id/featured_locations(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_forest_featured_locations_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"featured_locations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/forests/:forest_id/featured_locations(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_forest_featured_locations_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"featured_locations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/forests/:forest_id/forest_sections(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_forest_forest_sections_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/forests/:forest_id/forest_sections(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_forest_forest_sections_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/forests/:forest_id/points_of_interest(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_forest_points_of_interest_index_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"points_of_interest"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/forests/:forest_id/points_of_interest(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_forest_points_of_interest_index_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"points_of_interest"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/forests/:forest_id/trails(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_forest_trails_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"trails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/forests/:forest_id/trails(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_forest_trails_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"trails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shop/ash_spreadings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ash_spreadings_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shop"],[2,[7,"/"],[2,[6,"ash_spreadings"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /shop/ash_spreadings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ash_spreadings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shop"],[2,[7,"/"],[2,[6,"ash_spreadings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/at_need_costs/:at_need_cost_id/payments/:id(.:format)
 * @param {any} url_slug
 * @param {any} at_need_cost_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const at_need_cost_order_payment_url = __jsr.r({"url_slug":{"r":true},"at_need_cost_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/at_need_costs/:at_need_cost_id/payments/:id(.:format)
 * @param {any} url_slug
 * @param {any} at_need_cost_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const at_need_cost_order_payment_path = __jsr.r({"url_slug":{"r":true},"at_need_cost_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/at_need_costs/:at_need_cost_id/payments(.:format)
 * @param {any} url_slug
 * @param {any} at_need_cost_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const at_need_cost_order_payments_url = __jsr.r({"url_slug":{"r":true},"at_need_cost_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/at_need_costs/:at_need_cost_id/payments(.:format)
 * @param {any} url_slug
 * @param {any} at_need_cost_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const at_need_cost_order_payments_path = __jsr.r({"url_slug":{"r":true},"at_need_cost_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/ash_spreadings(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_ash_spreadings_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"ash_spreadings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/ash_spreadings(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_ash_spreadings_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"ash_spreadings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /checkouts/:url_slug/braintree_authorizations(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_braintree_authorizations_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"checkouts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"braintree_authorizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /checkouts/:url_slug/braintree_authorizations(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_braintree_authorizations_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"checkouts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"braintree_authorizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/email_notifications(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_email_notifications_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"email_notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/email_notifications(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_email_notifications_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"email_notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/metadata(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_metadata_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"metadata"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/metadata(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_metadata_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"metadata"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /checkouts/:url_slug/payment_completions(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_payment_completion_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"checkouts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payment_completions"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /checkouts/:url_slug/payment_completions(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_payment_completion_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"checkouts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payment_completions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/payment_plan(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_payment_plan_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"payment_plan"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/payment_plan(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_payment_plan_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"payment_plan"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/pet_spreadings(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_pet_spreadings_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"pet_spreadings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/pet_spreadings(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_pet_spreadings_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"pet_spreadings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /checkouts/:url_slug/promotions/:id(.:format)
 * @param {any} url_slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_promotion_url = __jsr.r({"url_slug":{"r":true},"id":{"r":true},"format":{"d":"js"}}, [2,[7,"/"],[2,[6,"checkouts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"promotions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /checkouts/:url_slug/promotions/:id(.:format)
 * @param {any} url_slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_promotion_path = __jsr.r({"url_slug":{"r":true},"id":{"r":true},"format":{"d":"js"}}, [2,[7,"/"],[2,[6,"checkouts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"promotions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /checkouts/:url_slug/promotions(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_promotions_url = __jsr.r({"url_slug":{"r":true},"format":{"d":"js"}}, [2,[7,"/"],[2,[6,"checkouts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"promotions"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /checkouts/:url_slug/promotions(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const checkout_promotions_path = __jsr.r({"url_slug":{"r":true},"format":{"d":"js"}}, [2,[7,"/"],[2,[6,"checkouts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"promotions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/complete(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const complete_checkout_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/complete(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const complete_checkout_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/at_need_costs/:at_need_cost_id/payments/completed(.:format)
 * @param {any} url_slug
 * @param {any} at_need_cost_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const completed_at_need_cost_order_payments_url = __jsr.r({"url_slug":{"r":true},"at_need_cost_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"completed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/at_need_costs/:at_need_cost_id/payments/completed(.:format)
 * @param {any} url_slug
 * @param {any} at_need_cost_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const completed_at_need_cost_order_payments_path = __jsr.r({"url_slug":{"r":true},"at_need_cost_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"completed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/my_forest/contact_support_team(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contact_support_team_dashboard_order_forest_status_page_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"my_forest"],[2,[7,"/"],[2,[6,"contact_support_team"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/my_forest/contact_support_team(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contact_support_team_dashboard_order_forest_status_page_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"my_forest"],[2,[7,"/"],[2,[6,"contact_support_team"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/payment_plan/continue_to_payment(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const continue_to_payment_checkout_payment_plan_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"payment_plan"],[2,[7,"/"],[2,[6,"continue_to_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/payment_plan/continue_to_payment(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const continue_to_payment_checkout_payment_plan_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"payment_plan"],[2,[7,"/"],[2,[6,"continue_to_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/terms_of_service(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const current_wishes_tos_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"terms_of_service"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/terms_of_service(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const current_wishes_tos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"terms_of_service"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/custom_amendments/:id(.:format)
 * @param {any} url_slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_amendment_url = __jsr.r({"url_slug":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"custom_amendments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/custom_amendments/:id(.:format)
 * @param {any} url_slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const custom_amendment_path = __jsr.r({"url_slug":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"custom_amendments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customers/:customer_id/referrals(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_referrals_url = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"referrals"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /customers/:customer_id/referrals(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_referrals_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"referrals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /dashboard/forest_memorials/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_forest_memorial_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"forest_memorials"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/forest_memorials/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_forest_memorial_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"forest_memorials"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/forest_memorials(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_forest_memorials_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"forest_memorials"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/forest_memorials(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_forest_memorials_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"forest_memorials"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/help(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_help_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"help"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/help(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_help_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"help"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/documents(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_order_documents_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/documents(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_order_documents_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/my_forest(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_order_forest_status_page_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"my_forest"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/my_forest(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_order_forest_status_page_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"my_forest"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/meet_my_tree(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_order_meet_my_tree_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"meet_my_tree"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/meet_my_tree(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_order_meet_my_tree_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"meet_my_tree"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/meet_my_tree/share(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_order_meet_my_tree_share_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"meet_my_tree"],[2,[7,"/"],[2,[6,"share"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/meet_my_tree/share(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_order_meet_my_tree_share_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"meet_my_tree"],[2,[7,"/"],[2,[6,"share"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payments/:id(.:format)
 * @param {any} url_slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_order_payment_url = __jsr.r({"url_slug":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payments/:id(.:format)
 * @param {any} url_slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_order_payment_path = __jsr.r({"url_slug":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payment_method(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_order_payment_method_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payment_method"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payment_method(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_order_payment_method_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payment_method"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payments(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_order_payments_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payments(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_order_payments_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/resources(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_order_resources_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"resources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/resources(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_order_resources_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"resources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/tree(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_order_tree_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"tree"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/tree(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_order_tree_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"tree"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_root_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /dashboard/tokens/:token_id/redeem(.:format)
 * @param {any} token_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_token_redeem_url = __jsr.r({"token_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"tokens"],[2,[7,"/"],[2,[3,"token_id"],[2,[7,"/"],[2,[6,"redeem"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/tokens/:token_id/redeem(.:format)
 * @param {any} token_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_token_redeem_path = __jsr.r({"token_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"tokens"],[2,[7,"/"],[2,[3,"token_id"],[2,[7,"/"],[2,[6,"redeem"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/tokens/:token_id/sign_up(.:format)
 * @param {any} token_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_token_sign_up_url = __jsr.r({"token_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"tokens"],[2,[7,"/"],[2,[3,"token_id"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/tokens/:token_id/sign_up(.:format)
 * @param {any} token_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_token_sign_up_path = __jsr.r({"token_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"tokens"],[2,[7,"/"],[2,[3,"token_id"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/tokens/:token_id/with_email(.:format)
 * @param {any} token_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_token_with_email_url = __jsr.r({"token_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"tokens"],[2,[7,"/"],[2,[3,"token_id"],[2,[7,"/"],[2,[6,"with_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/tokens/:token_id/with_email(.:format)
 * @param {any} token_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_token_with_email_path = __jsr.r({"token_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"tokens"],[2,[7,"/"],[2,[3,"token_id"],[2,[7,"/"],[2,[6,"with_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payment_method/default_card_number(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const default_card_number_dashboard_order_payment_method_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payment_method"],[2,[7,"/"],[2,[6,"default_card_number"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payment_method/default_card_number(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const default_card_number_dashboard_order_payment_method_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payment_method"],[2,[7,"/"],[2,[6,"default_card_number"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_customer_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /customers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payment_method/edit(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_dashboard_order_payment_method_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payment_method"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payment_method/edit(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_dashboard_order_payment_method_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payment_method"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/at_need_costs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_at_need_cost_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/at_need_costs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_at_need_cost_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/customers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_customer_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/customers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/employees/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_employee_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/employees/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_employee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/collections/:id/edit(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_collection_url = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/collections/:id/edit(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_collection_path = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/csv_uploader/edit(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_csv_uploader_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"csv_uploader"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/csv_uploader/edit(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_csv_uploader_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"csv_uploader"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_product_types/:id/edit(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_forest_product_type_url = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_product_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_product_types/:id/edit(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_forest_product_type_path = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_product_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/:id/edit(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_forest_section_url = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/:id/edit(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_forest_section_path = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/:forest_section_id/map_image/edit(.:format)
 * @param {any} forest_id
 * @param {any} forest_section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_forest_section_map_image_url = __jsr.r({"forest_id":{"r":true},"forest_section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[3,"forest_section_id"],[2,[7,"/"],[2,[6,"map_image"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/:forest_section_id/map_image/edit(.:format)
 * @param {any} forest_id
 * @param {any} forest_section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_forest_section_map_image_path = __jsr.r({"forest_id":{"r":true},"forest_section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[3,"forest_section_id"],[2,[7,"/"],[2,[6,"map_image"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/:forest_section_id/photos/:id/edit(.:format)
 * @param {any} forest_id
 * @param {any} forest_section_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_forest_section_photo_url = __jsr.r({"forest_id":{"r":true},"forest_section_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[3,"forest_section_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/:forest_section_id/photos/:id/edit(.:format)
 * @param {any} forest_id
 * @param {any} forest_section_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_forest_section_photo_path = __jsr.r({"forest_id":{"r":true},"forest_section_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[3,"forest_section_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/photo_uploader/edit(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_photo_uploader_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"photo_uploader"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/photo_uploader/edit(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_photo_uploader_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"photo_uploader"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/pricing_schemas/:id/edit(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_pricing_schema_url = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"pricing_schemas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/pricing_schemas/:id/edit(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_pricing_schema_path = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"pricing_schemas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/subsections/:id/edit(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_subsection_url = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"subsections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/subsections/:id/edit(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_forest_subsection_path = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"subsections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_memorial_ceremony_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_memorial_ceremony_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:memorial_ceremony_id/spreadings/:id/edit(.:format)
 * @param {any} memorial_ceremony_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_memorial_ceremony_spreading_url = __jsr.r({"memorial_ceremony_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"memorial_ceremony_id"],[2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:memorial_ceremony_id/spreadings/:id/edit(.:format)
 * @param {any} memorial_ceremony_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_memorial_ceremony_spreading_path = __jsr.r({"memorial_ceremony_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"memorial_ceremony_id"],[2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_order_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/at_need_costs/:at_need_cost_id/payments/:id/edit(.:format)
 * @param {any} order_id
 * @param {any} at_need_cost_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_order_at_need_cost_payment_url = __jsr.r({"order_id":{"r":true},"at_need_cost_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/at_need_costs/:at_need_cost_id/payments/:id/edit(.:format)
 * @param {any} order_id
 * @param {any} at_need_cost_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_order_at_need_cost_payment_path = __jsr.r({"order_id":{"r":true},"at_need_cost_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/maintenance_rules/:id/edit(.:format)
 * @param {any} order_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_order_maintenance_rule_url = __jsr.r({"order_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"maintenance_rules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/maintenance_rules/:id/edit(.:format)
 * @param {any} order_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_order_maintenance_rule_path = __jsr.r({"order_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"maintenance_rules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/payment_plans/:id/edit(.:format)
 * @param {any} order_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_order_payment_plan_url = __jsr.r({"order_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"payment_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/payment_plans/:id/edit(.:format)
 * @param {any} order_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_order_payment_plan_path = __jsr.r({"order_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"payment_plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/payment_plans/:payment_plan_id/payments/:id/edit(.:format)
 * @param {any} order_id
 * @param {any} payment_plan_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_order_payment_plan_payment_url = __jsr.r({"order_id":{"r":true},"payment_plan_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"payment_plans"],[2,[7,"/"],[2,[3,"payment_plan_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/payment_plans/:payment_plan_id/payments/:id/edit(.:format)
 * @param {any} order_id
 * @param {any} payment_plan_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_order_payment_plan_payment_path = __jsr.r({"order_id":{"r":true},"payment_plan_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"payment_plans"],[2,[7,"/"],[2,[3,"payment_plan_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/terms_of_purchase/edit(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_order_terms_of_purchase_url = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"terms_of_purchase"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/terms_of_purchase/edit(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_order_terms_of_purchase_path = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"terms_of_purchase"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/promotions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_promotion_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"promotions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/promotions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_promotion_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"promotions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/salesforce/order_roles_validator/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_salesforce_order_roles_validator_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"order_roles_validator"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/salesforce/order_roles_validator/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_salesforce_order_roles_validator_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"order_roles_validator"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/species/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_species_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/species/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_species_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/species/:species_id/forest_species/:id/edit(.:format)
 * @param {any} species_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_species_forest_species_url = __jsr.r({"species_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"forest_species"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/species/:species_id/forest_species/:id/edit(.:format)
 * @param {any} species_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_species_forest_species_path = __jsr.r({"species_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"forest_species"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/species/:species_id/species_illustration/edit(.:format)
 * @param {any} species_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_species_species_illustration_url = __jsr.r({"species_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"species_illustration"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/species/:species_id/species_illustration/edit(.:format)
 * @param {any} species_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_species_species_illustration_path = __jsr.r({"species_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"species_illustration"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/spreading_restrictions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_spreading_restriction_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"spreading_restrictions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/spreading_restrictions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_spreading_restriction_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"spreading_restrictions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_tree_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_tree_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/maintenance_rules/:id/edit(.:format)
 * @param {any} tree_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_tree_maintenance_rule_url = __jsr.r({"tree_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"maintenance_rules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/maintenance_rules/:id/edit(.:format)
 * @param {any} tree_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_tree_maintenance_rule_path = __jsr.r({"tree_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"maintenance_rules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/photos/:id/edit(.:format)
 * @param {any} tree_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_tree_photo_url = __jsr.r({"tree_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/photos/:id/edit(.:format)
 * @param {any} tree_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_tree_photo_path = __jsr.r({"tree_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/spreadings/:id/edit(.:format)
 * @param {any} tree_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_tree_spreading_url = __jsr.r({"tree_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/spreadings/:id/edit(.:format)
 * @param {any} tree_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_tree_spreading_path = __jsr.r({"tree_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_visit_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_visit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/favorites/:id/edit(.:format)
 * @param {any} visit_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_visit_favorite_url = __jsr.r({"visit_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"favorites"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/favorites/:id/edit(.:format)
 * @param {any} visit_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_visit_favorite_path = __jsr.r({"visit_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"favorites"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/recommendations/:id/edit(.:format)
 * @param {any} visit_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_visit_recommendation_url = __jsr.r({"visit_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"recommendations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/recommendations/:id/edit(.:format)
 * @param {any} visit_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_internal_visit_recommendation_path = __jsr.r({"visit_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"recommendations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/registration/email_step(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const email_step_user_registration_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"registration"],[2,[7,"/"],[2,[6,"email_step"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/registration/email_step(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const email_step_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"registration"],[2,[7,"/"],[2,[6,"email_step"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/carts/:id/empty(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const empty_internal_cart_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"carts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"empty"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/carts/:id/empty(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const empty_internal_cart_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"carts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"empty"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/promotions/:id/expire(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const expire_internal_promotion_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"promotions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"expire"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/promotions/:id/expire(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const expire_internal_promotion_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"promotions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"expire"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/expired(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const expired_checkout_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"expired"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/expired(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const expired_checkout_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"expired"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/finished(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finished_checkout_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"finished"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/finished(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const finished_checkout_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"finished"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ghost(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ghost_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ghost"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /ghost(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ghost_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ghost"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /hello_sign_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hello_sign_document_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"hello_sign_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /hello_sign_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hello_sign_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"hello_sign_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payments/history(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const history_dashboard_order_payments_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"history"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payments/history(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const history_dashboard_order_payments_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"history"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shop/check_ins/:uuid(.:format)
 * @param {any} uuid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hosted_tour_check_in_url = __jsr.r({"uuid":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shop"],[2,[7,"/"],[2,[6,"check_ins"],[2,[7,"/"],[2,[3,"uuid"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /shop/check_ins/:uuid(.:format)
 * @param {any} uuid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hosted_tour_check_in_path = __jsr.r({"uuid":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shop"],[2,[7,"/"],[2,[6,"check_ins"],[2,[7,"/"],[2,[3,"uuid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/api/referral_applications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_api_referral_applications_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"referral_applications"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/api/referral_applications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_api_referral_applications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"referral_applications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/api/salesforce/memorial_ceremonies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_api_salesforce_memorial_ceremonies_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/api/salesforce/memorial_ceremonies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_api_salesforce_memorial_ceremonies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/api/salesforce/persistent_customer_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_api_salesforce_persistent_customer_info_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"persistent_customer_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/api/salesforce/persistent_customer_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_api_salesforce_persistent_customer_info_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"persistent_customer_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/api/salesforce/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_api_salesforce_users_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/api/salesforce/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_api_salesforce_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/api/salesforce/visits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_api_salesforce_visits_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"visits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/api/salesforce/visits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_api_salesforce_visits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"visits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/api/v1/experiment_value_for(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_api_v1_experiment_value_for_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"experiment_value_for"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/api/v1/experiment_value_for(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_api_v1_experiment_value_for_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"experiment_value_for"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/api/v1/referrals/referral_codes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_api_v1_referrals_referral_code_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"referrals"],[2,[7,"/"],[2,[6,"referral_codes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/api/v1/referrals/referral_codes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_api_v1_referrals_referral_code_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"referrals"],[2,[7,"/"],[2,[6,"referral_codes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/api/visits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_api_visits_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"visits"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/api/visits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_api_visits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"visits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/api/visits_confirmations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_api_visits_confirmations_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"visits_confirmations"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/api/visits_confirmations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_api_visits_confirmations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"visits_confirmations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/at_need_costs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_at_need_cost_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/at_need_costs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_at_need_cost_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/at_need_costs/:at_need_cost_id/breakdown(.:format)
 * @param {any} at_need_cost_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_at_need_cost_breakdown_index_url = __jsr.r({"at_need_cost_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"breakdown"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/at_need_costs/:at_need_cost_id/breakdown(.:format)
 * @param {any} at_need_cost_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_at_need_cost_breakdown_index_path = __jsr.r({"at_need_cost_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"breakdown"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/at_need_costs/:at_need_cost_id/spreading_associations(.:format)
 * @param {any} at_need_cost_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_at_need_cost_spreading_associations_url = __jsr.r({"at_need_cost_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"spreading_associations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/at_need_costs/:at_need_cost_id/spreading_associations(.:format)
 * @param {any} at_need_cost_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_at_need_cost_spreading_associations_path = __jsr.r({"at_need_cost_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"spreading_associations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/carts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_cart_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"carts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/carts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_cart_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"carts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customer_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/customers/:customer_id/hello_sign_documents(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customer_hello_sign_documents_url = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"hello_sign_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/customers/:customer_id/hello_sign_documents(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customer_hello_sign_documents_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"hello_sign_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/customers/:customer_id/referrals/:referral_id/choose(.:format)
 * @param {any} customer_id
 * @param {any} referral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customer_referral_choose_url = __jsr.r({"customer_id":{"r":true},"referral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"referrals"],[2,[7,"/"],[2,[3,"referral_id"],[2,[7,"/"],[2,[6,"choose"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/customers/:customer_id/referrals/:referral_id/choose(.:format)
 * @param {any} customer_id
 * @param {any} referral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customer_referral_choose_path = __jsr.r({"customer_id":{"r":true},"referral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"referrals"],[2,[7,"/"],[2,[3,"referral_id"],[2,[7,"/"],[2,[6,"choose"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/customers/:customer_id/referrals/:referral_id/mark_pitch_scheduled(.:format)
 * @param {any} customer_id
 * @param {any} referral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customer_referral_mark_pitch_scheduled_url = __jsr.r({"customer_id":{"r":true},"referral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"referrals"],[2,[7,"/"],[2,[3,"referral_id"],[2,[7,"/"],[2,[6,"mark_pitch_scheduled"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/customers/:customer_id/referrals/:referral_id/mark_pitch_scheduled(.:format)
 * @param {any} customer_id
 * @param {any} referral_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customer_referral_mark_pitch_scheduled_path = __jsr.r({"customer_id":{"r":true},"referral_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"referrals"],[2,[7,"/"],[2,[3,"referral_id"],[2,[7,"/"],[2,[6,"mark_pitch_scheduled"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/customers/:customer_id/referrals(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customer_referrals_url = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"referrals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/customers/:customer_id/referrals(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customer_referrals_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"referrals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/customers/:customer_id/send_reset_password_email(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customer_send_reset_password_email_url = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"send_reset_password_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/customers/:customer_id/send_reset_password_email(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customer_send_reset_password_email_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"send_reset_password_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/customers/:customer_id/site_liability_waivers/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customer_site_liability_waiver_url = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"site_liability_waivers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/customers/:customer_id/site_liability_waivers/:id(.:format)
 * @param {any} customer_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customer_site_liability_waiver_path = __jsr.r({"customer_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"site_liability_waivers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/customers/:customer_id/site_liability_waivers(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customer_site_liability_waivers_url = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"site_liability_waivers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/customers/:customer_id/site_liability_waivers(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customer_site_liability_waivers_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"site_liability_waivers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/customers/:customer_id/user_identifier(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customer_user_identifier_url = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"user_identifier"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/customers/:customer_id/user_identifier(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customer_user_identifier_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"user_identifier"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customers_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/dbh_thresholds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_dbh_thresholds_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"dbh_thresholds"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/dbh_thresholds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_dbh_thresholds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"dbh_thresholds"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/employees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_employee_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/employees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_employee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/employees/:employee_id/deactivate(.:format)
 * @param {any} employee_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_employee_deactivate_url = __jsr.r({"employee_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[3,"employee_id"],[2,[7,"/"],[2,[6,"deactivate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/employees/:employee_id/deactivate(.:format)
 * @param {any} employee_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_employee_deactivate_path = __jsr.r({"employee_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[3,"employee_id"],[2,[7,"/"],[2,[6,"deactivate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_employees_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_employees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/filtered_species(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_filtered_species_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"filtered_species"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/filtered_species(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_filtered_species_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"filtered_species"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/collections/:id(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_collection_url = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/collections/:id(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_collection_path = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/collection_members/:id(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_collection_member_url = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"collection_members"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/collection_members/:id(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_collection_member_path = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"collection_members"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/collections(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_collections_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"collections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/collections(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_collections_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"collections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/csv_uploader(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_csv_uploader_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"csv_uploader"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/csv_uploader(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_csv_uploader_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"csv_uploader"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_product_types/:id(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_forest_product_type_url = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_product_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_product_types/:id(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_forest_product_type_path = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_product_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_product_types/:forest_product_type_id/photos/:id(.:format)
 * @param {any} forest_id
 * @param {any} forest_product_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_forest_product_type_photo_url = __jsr.r({"forest_id":{"r":true},"forest_product_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_product_types"],[2,[7,"/"],[2,[3,"forest_product_type_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_product_types/:forest_product_type_id/photos/:id(.:format)
 * @param {any} forest_id
 * @param {any} forest_product_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_forest_product_type_photo_path = __jsr.r({"forest_id":{"r":true},"forest_product_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_product_types"],[2,[7,"/"],[2,[3,"forest_product_type_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_product_types/:forest_product_type_id/photos(.:format)
 * @param {any} forest_id
 * @param {any} forest_product_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_forest_product_type_photos_url = __jsr.r({"forest_id":{"r":true},"forest_product_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_product_types"],[2,[7,"/"],[2,[3,"forest_product_type_id"],[2,[7,"/"],[2,[6,"photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_product_types/:forest_product_type_id/photos(.:format)
 * @param {any} forest_id
 * @param {any} forest_product_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_forest_product_type_photos_path = __jsr.r({"forest_id":{"r":true},"forest_product_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_product_types"],[2,[7,"/"],[2,[3,"forest_product_type_id"],[2,[7,"/"],[2,[6,"photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_product_types(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_forest_product_types_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_product_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_product_types(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_forest_product_types_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_product_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/:id(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_forest_section_url = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/:id(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_forest_section_path = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/:forest_section_id/map_image(.:format)
 * @param {any} forest_id
 * @param {any} forest_section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_forest_section_map_image_url = __jsr.r({"forest_id":{"r":true},"forest_section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[3,"forest_section_id"],[2,[7,"/"],[2,[6,"map_image"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/:forest_section_id/map_image(.:format)
 * @param {any} forest_id
 * @param {any} forest_section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_forest_section_map_image_path = __jsr.r({"forest_id":{"r":true},"forest_section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[3,"forest_section_id"],[2,[7,"/"],[2,[6,"map_image"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/:forest_section_id/photos/:id(.:format)
 * @param {any} forest_id
 * @param {any} forest_section_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_forest_section_photo_url = __jsr.r({"forest_id":{"r":true},"forest_section_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[3,"forest_section_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/:forest_section_id/photos/:id(.:format)
 * @param {any} forest_id
 * @param {any} forest_section_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_forest_section_photo_path = __jsr.r({"forest_id":{"r":true},"forest_section_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[3,"forest_section_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/:forest_section_id/photos(.:format)
 * @param {any} forest_id
 * @param {any} forest_section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_forest_section_photos_url = __jsr.r({"forest_id":{"r":true},"forest_section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[3,"forest_section_id"],[2,[7,"/"],[2,[6,"photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/:forest_section_id/photos(.:format)
 * @param {any} forest_id
 * @param {any} forest_section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_forest_section_photos_path = __jsr.r({"forest_id":{"r":true},"forest_section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[3,"forest_section_id"],[2,[7,"/"],[2,[6,"photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_forest_sections_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_forest_sections_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/photos/:id(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_photo_url = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/photos/:id(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_photo_path = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/photo_uploader(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_photo_uploader_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"photo_uploader"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/photo_uploader(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_photo_uploader_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"photo_uploader"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/photos(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_photos_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/photos(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_photos_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/photos/bulk(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_photos_bulk_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[6,"bulk"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/photos/bulk(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_photos_bulk_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[6,"bulk"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/pricing_schemas/:id(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_pricing_schema_url = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"pricing_schemas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/pricing_schemas/:id(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_pricing_schema_path = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"pricing_schemas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/pricing_schemas/:pricing_schema_id/export_as_csv(.:format)
 * @param {any} forest_id
 * @param {any} pricing_schema_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_pricing_schema_export_as_csv_url = __jsr.r({"forest_id":{"r":true},"pricing_schema_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"pricing_schemas"],[2,[7,"/"],[2,[3,"pricing_schema_id"],[2,[7,"/"],[2,[6,"export_as_csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/pricing_schemas/:pricing_schema_id/export_as_csv(.:format)
 * @param {any} forest_id
 * @param {any} pricing_schema_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_pricing_schema_export_as_csv_path = __jsr.r({"forest_id":{"r":true},"pricing_schema_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"pricing_schemas"],[2,[7,"/"],[2,[3,"pricing_schema_id"],[2,[7,"/"],[2,[6,"export_as_csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/pricing_schemas(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_pricing_schemas_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"pricing_schemas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/pricing_schemas(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_pricing_schemas_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"pricing_schemas"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forest_sections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_sections_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forest_sections"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forest_sections(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_sections_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forest_sections"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/forest_sections_reorderer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_sections_reorderer_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forest_sections_reorderer"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forest_sections_reorderer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_sections_reorderer_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forest_sections_reorderer"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/subsections/:id(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_subsection_url = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"subsections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/subsections/:id(.:format)
 * @param {any} forest_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_subsection_path = __jsr.r({"forest_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"subsections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/subsections/:subsection_id/photos/:id(.:format)
 * @param {any} forest_id
 * @param {any} subsection_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_subsection_photo_url = __jsr.r({"forest_id":{"r":true},"subsection_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"subsections"],[2,[7,"/"],[2,[3,"subsection_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/subsections/:subsection_id/photos/:id(.:format)
 * @param {any} forest_id
 * @param {any} subsection_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_subsection_photo_path = __jsr.r({"forest_id":{"r":true},"subsection_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"subsections"],[2,[7,"/"],[2,[3,"subsection_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forest_visit_summaries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_visit_summaries_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forest_visit_summaries"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forest_visit_summaries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_visit_summaries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forest_visit_summaries"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/forest_visit_summaries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_visit_summary_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forest_visit_summaries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forest_visit_summaries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_visit_summary_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forest_visit_summaries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forest_visit_summaries/:forest_visit_summary_id/visit_photos(.:format)
 * @param {any} forest_visit_summary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_visit_summary_visit_photos_url = __jsr.r({"forest_visit_summary_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forest_visit_summaries"],[2,[7,"/"],[2,[3,"forest_visit_summary_id"],[2,[7,"/"],[2,[6,"visit_photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forest_visit_summaries/:forest_visit_summary_id/visit_photos(.:format)
 * @param {any} forest_visit_summary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forest_visit_summary_visit_photos_path = __jsr.r({"forest_visit_summary_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forest_visit_summaries"],[2,[7,"/"],[2,[3,"forest_visit_summary_id"],[2,[7,"/"],[2,[6,"visit_photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forests_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_forests_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/impact_trees_awards/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_impact_trees_award_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"impact_trees_awards"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/impact_trees_awards/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_impact_trees_award_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"impact_trees_awards"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/impact_trees_awards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_impact_trees_awards_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"impact_trees_awards"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/impact_trees_awards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_impact_trees_awards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"impact_trees_awards"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/launch_phases/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_launch_phase_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"launch_phases"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/launch_phases/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_launch_phase_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"launch_phases"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/launch_phases(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_launch_phases_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"launch_phases"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/launch_phases(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_launch_phases_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"launch_phases"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_memorial_ceremonies_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_memorial_ceremonies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_memorial_ceremony_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_memorial_ceremony_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:memorial_ceremony_id/hello_sign_documents/:id(.:format)
 * @param {any} memorial_ceremony_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_memorial_ceremony_hello_sign_document_url = __jsr.r({"memorial_ceremony_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"memorial_ceremony_id"],[2,[7,"/"],[2,[6,"hello_sign_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:memorial_ceremony_id/hello_sign_documents/:id(.:format)
 * @param {any} memorial_ceremony_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_memorial_ceremony_hello_sign_document_path = __jsr.r({"memorial_ceremony_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"memorial_ceremony_id"],[2,[7,"/"],[2,[6,"hello_sign_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:memorial_ceremony_id/hello_sign_documents(.:format)
 * @param {any} memorial_ceremony_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_memorial_ceremony_hello_sign_documents_url = __jsr.r({"memorial_ceremony_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"memorial_ceremony_id"],[2,[7,"/"],[2,[6,"hello_sign_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:memorial_ceremony_id/hello_sign_documents(.:format)
 * @param {any} memorial_ceremony_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_memorial_ceremony_hello_sign_documents_path = __jsr.r({"memorial_ceremony_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"memorial_ceremony_id"],[2,[7,"/"],[2,[6,"hello_sign_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:memorial_ceremony_id/spreadings/:id(.:format)
 * @param {any} memorial_ceremony_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_memorial_ceremony_spreading_url = __jsr.r({"memorial_ceremony_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"memorial_ceremony_id"],[2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:memorial_ceremony_id/spreadings/:id(.:format)
 * @param {any} memorial_ceremony_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_memorial_ceremony_spreading_path = __jsr.r({"memorial_ceremony_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"memorial_ceremony_id"],[2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:memorial_ceremony_id/spreadings/:spreading_id/documents(.:format)
 * @param {any} memorial_ceremony_id
 * @param {any} spreading_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_memorial_ceremony_spreading_documents_url = __jsr.r({"memorial_ceremony_id":{"r":true},"spreading_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"memorial_ceremony_id"],[2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[3,"spreading_id"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:memorial_ceremony_id/spreadings/:spreading_id/documents(.:format)
 * @param {any} memorial_ceremony_id
 * @param {any} spreading_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_memorial_ceremony_spreading_documents_path = __jsr.r({"memorial_ceremony_id":{"r":true},"spreading_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"memorial_ceremony_id"],[2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[3,"spreading_id"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:memorial_ceremony_id/spreadings/:spreading_id/hello_sign_documents/:id(.:format)
 * @param {any} memorial_ceremony_id
 * @param {any} spreading_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_memorial_ceremony_spreading_hello_sign_document_url = __jsr.r({"memorial_ceremony_id":{"r":true},"spreading_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"memorial_ceremony_id"],[2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[3,"spreading_id"],[2,[7,"/"],[2,[6,"hello_sign_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:memorial_ceremony_id/spreadings/:spreading_id/hello_sign_documents/:id(.:format)
 * @param {any} memorial_ceremony_id
 * @param {any} spreading_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_memorial_ceremony_spreading_hello_sign_document_path = __jsr.r({"memorial_ceremony_id":{"r":true},"spreading_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"memorial_ceremony_id"],[2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[3,"spreading_id"],[2,[7,"/"],[2,[6,"hello_sign_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:memorial_ceremony_id/spreadings/:spreading_id/hello_sign_documents(.:format)
 * @param {any} memorial_ceremony_id
 * @param {any} spreading_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_memorial_ceremony_spreading_hello_sign_documents_url = __jsr.r({"memorial_ceremony_id":{"r":true},"spreading_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"memorial_ceremony_id"],[2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[3,"spreading_id"],[2,[7,"/"],[2,[6,"hello_sign_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:memorial_ceremony_id/spreadings/:spreading_id/hello_sign_documents(.:format)
 * @param {any} memorial_ceremony_id
 * @param {any} spreading_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_memorial_ceremony_spreading_hello_sign_documents_path = __jsr.r({"memorial_ceremony_id":{"r":true},"spreading_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"memorial_ceremony_id"],[2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[3,"spreading_id"],[2,[7,"/"],[2,[6,"hello_sign_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:memorial_ceremony_id/spreadings(.:format)
 * @param {any} memorial_ceremony_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_memorial_ceremony_spreadings_url = __jsr.r({"memorial_ceremony_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"memorial_ceremony_id"],[2,[7,"/"],[2,[6,"spreadings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:memorial_ceremony_id/spreadings(.:format)
 * @param {any} memorial_ceremony_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_memorial_ceremony_spreadings_path = __jsr.r({"memorial_ceremony_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"memorial_ceremony_id"],[2,[7,"/"],[2,[6,"spreadings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/amendments(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_amendments_url = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"amendments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/amendments(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_amendments_path = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"amendments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/at_need_costs/:at_need_cost_id/payments/:id(.:format)
 * @param {any} order_id
 * @param {any} at_need_cost_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_at_need_cost_payment_url = __jsr.r({"order_id":{"r":true},"at_need_cost_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/at_need_costs/:at_need_cost_id/payments/:id(.:format)
 * @param {any} order_id
 * @param {any} at_need_cost_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_at_need_cost_payment_path = __jsr.r({"order_id":{"r":true},"at_need_cost_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/at_need_costs/:at_need_cost_id/payments(.:format)
 * @param {any} order_id
 * @param {any} at_need_cost_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_at_need_cost_payments_url = __jsr.r({"order_id":{"r":true},"at_need_cost_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/at_need_costs/:at_need_cost_id/payments(.:format)
 * @param {any} order_id
 * @param {any} at_need_cost_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_at_need_cost_payments_path = __jsr.r({"order_id":{"r":true},"at_need_cost_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/at_need_costs(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_at_need_costs_url = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"at_need_costs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/at_need_costs(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_at_need_costs_path = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"at_need_costs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/checkout_metadata(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_checkout_metadata_url = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"checkout_metadata"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/checkout_metadata(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_checkout_metadata_path = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"checkout_metadata"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/hello_sign_documents/:id(.:format)
 * @param {any} order_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_hello_sign_document_url = __jsr.r({"order_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"hello_sign_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/hello_sign_documents/:id(.:format)
 * @param {any} order_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_hello_sign_document_path = __jsr.r({"order_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"hello_sign_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/hello_sign_documents(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_hello_sign_documents_url = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"hello_sign_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/hello_sign_documents(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_hello_sign_documents_path = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"hello_sign_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/maintenance_rules/:id(.:format)
 * @param {any} order_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_maintenance_rule_url = __jsr.r({"order_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"maintenance_rules"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/maintenance_rules/:id(.:format)
 * @param {any} order_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_maintenance_rule_path = __jsr.r({"order_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"maintenance_rules"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/maintenance_rules(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_maintenance_rules_url = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"maintenance_rules"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/maintenance_rules(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_maintenance_rules_path = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"maintenance_rules"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/payment_plans/:id(.:format)
 * @param {any} order_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_payment_plan_url = __jsr.r({"order_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"payment_plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/payment_plans/:id(.:format)
 * @param {any} order_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_payment_plan_path = __jsr.r({"order_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"payment_plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/payment_plans/:payment_plan_id/payments/:id(.:format)
 * @param {any} order_id
 * @param {any} payment_plan_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_payment_plan_payment_url = __jsr.r({"order_id":{"r":true},"payment_plan_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"payment_plans"],[2,[7,"/"],[2,[3,"payment_plan_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/payment_plans/:payment_plan_id/payments/:id(.:format)
 * @param {any} order_id
 * @param {any} payment_plan_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_payment_plan_payment_path = __jsr.r({"order_id":{"r":true},"payment_plan_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"payment_plans"],[2,[7,"/"],[2,[3,"payment_plan_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/payment_plans/:payment_plan_id/payments(.:format)
 * @param {any} order_id
 * @param {any} payment_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_payment_plan_payments_url = __jsr.r({"order_id":{"r":true},"payment_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"payment_plans"],[2,[7,"/"],[2,[3,"payment_plan_id"],[2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/payment_plans/:payment_plan_id/payments(.:format)
 * @param {any} order_id
 * @param {any} payment_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_payment_plan_payments_path = __jsr.r({"order_id":{"r":true},"payment_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"payment_plans"],[2,[7,"/"],[2,[3,"payment_plan_id"],[2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/payment_plans(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_payment_plans_url = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"payment_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/payment_plans(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_payment_plans_path = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"payment_plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/order_roles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_roles_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"order_roles"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/order_roles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_roles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"order_roles"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/terms_of_purchase(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_terms_of_purchase_url = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"terms_of_purchase"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/terms_of_purchase(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_terms_of_purchase_path = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"terms_of_purchase"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/transfers(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_transfers_url = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/transfers(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_transfers_path = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/update_status(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_update_status_url = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/update_status(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_order_update_status_path = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_orders_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_orders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/pages/change_confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_pages_change_confirmation_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"change_confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/pages/change_confirmation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_pages_change_confirmation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"pages"],[2,[7,"/"],[2,[6,"change_confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/photo_reorderer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_photo_reorderer_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"photo_reorderer"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/photo_reorderer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_photo_reorderer_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"photo_reorderer"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/photo_tags/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_photo_tag_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"photo_tags"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/photo_tags/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_photo_tag_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"photo_tags"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/photo_tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_photo_tags_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"photo_tags"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/photo_tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_photo_tags_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"photo_tags"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/promotions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_promotion_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"promotions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/promotions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_promotion_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"promotions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/promotions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_promotions_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"promotions"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/promotions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_promotions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"promotions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/purchase_celebrations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_purchase_celebration_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"purchase_celebrations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/purchase_celebrations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_purchase_celebration_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"purchase_celebrations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/records_reorderer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_records_reorderer_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"records_reorderer"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/records_reorderer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_records_reorderer_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"records_reorderer"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_root_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /internal(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /internal/salesforce/order_roles_validator(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_salesforce_order_roles_validator_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"order_roles_validator"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/salesforce/order_roles_validator(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_salesforce_order_roles_validator_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"order_roles_validator"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/sfmc(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_sfmc_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"sfmc"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/sfmc(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_sfmc_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"sfmc"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/species/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_species_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/species/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_species_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/species/:species_id/forest_species/:id(.:format)
 * @param {any} species_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_species_forest_species_url = __jsr.r({"species_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"forest_species"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/species/:species_id/forest_species/:id(.:format)
 * @param {any} species_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_species_forest_species_path = __jsr.r({"species_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"forest_species"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/species/:species_id/forest_species(.:format)
 * @param {any} species_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_species_forest_species_index_url = __jsr.r({"species_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"forest_species"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/species/:species_id/forest_species(.:format)
 * @param {any} species_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_species_forest_species_index_path = __jsr.r({"species_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"forest_species"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/species/:species_id/forest_species/:forest_species_id/photos/:id(.:format)
 * @param {any} species_id
 * @param {any} forest_species_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_species_forest_species_photo_url = __jsr.r({"species_id":{"r":true},"forest_species_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"forest_species"],[2,[7,"/"],[2,[3,"forest_species_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/species/:species_id/forest_species/:forest_species_id/photos/:id(.:format)
 * @param {any} species_id
 * @param {any} forest_species_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_species_forest_species_photo_path = __jsr.r({"species_id":{"r":true},"forest_species_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"forest_species"],[2,[7,"/"],[2,[3,"forest_species_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/species/:species_id/forest_species/:forest_species_id/photos(.:format)
 * @param {any} species_id
 * @param {any} forest_species_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_species_forest_species_photos_url = __jsr.r({"species_id":{"r":true},"forest_species_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"forest_species"],[2,[7,"/"],[2,[3,"forest_species_id"],[2,[7,"/"],[2,[6,"photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/species/:species_id/forest_species/:forest_species_id/photos(.:format)
 * @param {any} species_id
 * @param {any} forest_species_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_species_forest_species_photos_path = __jsr.r({"species_id":{"r":true},"forest_species_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"forest_species"],[2,[7,"/"],[2,[3,"forest_species_id"],[2,[7,"/"],[2,[6,"photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/species(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_species_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/species(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_species_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/species/:species_id/photos/:id(.:format)
 * @param {any} species_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_species_photo_url = __jsr.r({"species_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/species/:species_id/photos/:id(.:format)
 * @param {any} species_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_species_photo_path = __jsr.r({"species_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/species/:species_id/photos(.:format)
 * @param {any} species_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_species_photos_url = __jsr.r({"species_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/species/:species_id/photos(.:format)
 * @param {any} species_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_species_photos_path = __jsr.r({"species_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/species/:species_id/species_illustration(.:format)
 * @param {any} species_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_species_species_illustration_url = __jsr.r({"species_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"species_illustration"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/species/:species_id/species_illustration(.:format)
 * @param {any} species_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_species_species_illustration_path = __jsr.r({"species_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"species_illustration"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/spreading_restrictions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_spreading_restriction_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"spreading_restrictions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/spreading_restrictions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_spreading_restriction_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"spreading_restrictions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/spreading_restrictions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_spreading_restrictions_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"spreading_restrictions"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/spreading_restrictions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_spreading_restrictions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"spreading_restrictions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/transfers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_transfer_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/transfers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_transfer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/transfers/:transfer_id/select_tree(.:format)
 * @param {any} transfer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_transfer_select_tree_url = __jsr.r({"transfer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[3,"transfer_id"],[2,[7,"/"],[2,[6,"select_tree"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/transfers/:transfer_id/select_tree(.:format)
 * @param {any} transfer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_transfer_select_tree_path = __jsr.r({"transfer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[3,"transfer_id"],[2,[7,"/"],[2,[6,"select_tree"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/transfers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_transfers_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"transfers"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/transfers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_transfers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/tree_certificates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_certificates_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tree_certificates"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/tree_certificates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_certificates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tree_certificates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/details_modal(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_details_modal_index_url = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"details_modal"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/details_modal(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_details_modal_index_path = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"details_modal"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/health_issues(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_health_issues_url = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"health_issues"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/health_issues(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_health_issues_path = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"health_issues"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/tree_holds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_hold_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tree_holds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/tree_holds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_hold_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tree_holds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/tree_holds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_holds_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tree_holds"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/tree_holds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_holds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tree_holds"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/maintenance_rules/:id(.:format)
 * @param {any} tree_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_maintenance_rule_url = __jsr.r({"tree_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"maintenance_rules"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/maintenance_rules/:id(.:format)
 * @param {any} tree_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_maintenance_rule_path = __jsr.r({"tree_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"maintenance_rules"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/maintenance_rules(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_maintenance_rules_url = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"maintenance_rules"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/maintenance_rules(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_maintenance_rules_path = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"maintenance_rules"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/contracts(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_orders_url = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/contracts(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_orders_path = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/photos/:id(.:format)
 * @param {any} tree_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_photo_url = __jsr.r({"tree_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/photos/:id(.:format)
 * @param {any} tree_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_photo_path = __jsr.r({"tree_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/photos(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_photos_url = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/photos(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_photos_path = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/spreadings/:id(.:format)
 * @param {any} tree_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_spreading_url = __jsr.r({"tree_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/spreadings/:id(.:format)
 * @param {any} tree_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_spreading_path = __jsr.r({"tree_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/tree_holds(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_tree_holds_url = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"tree_holds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/tree_holds(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_tree_holds_path = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"tree_holds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/health_issues(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_update_health_issues_url = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"health_issues"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/health_issues(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_update_health_issues_path = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"health_issues"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/videos/:id(.:format)
 * @param {any} tree_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_video_url = __jsr.r({"tree_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"videos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/videos/:id(.:format)
 * @param {any} tree_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_video_path = __jsr.r({"tree_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"videos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/videos(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_videos_url = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"videos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/videos(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_tree_videos_path = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"videos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_trees_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_trees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/user_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_user_settings_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"user_settings"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/user_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_user_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"user_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/cart_item(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_cart_item_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"cart_item"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/cart_item(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_cart_item_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"cart_item"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/check_in(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_check_in_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"check_in"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/check_in(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_check_in_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"check_in"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/check_in_now(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_check_in_now_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"check_in_now"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/check_in_now(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_check_in_now_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"check_in_now"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/check_out(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_check_out_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"check_out"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/check_out(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_check_out_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"check_out"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/create_follow_up_task(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_create_follow_up_task_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"create_follow_up_task"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/create_follow_up_task(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_create_follow_up_task_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"create_follow_up_task"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/create_sfdc_case(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_create_sfdc_case_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"create_sfdc_case"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/create_sfdc_case(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_create_sfdc_case_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"create_sfdc_case"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/create_sfdc_task(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_create_sfdc_task_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"create_sfdc_task"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/create_sfdc_task(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_create_sfdc_task_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"create_sfdc_task"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/customers(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_customers_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/customers(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_customers_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/destroy_photo(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_destroy_photo_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"destroy_photo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/destroy_photo(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_destroy_photo_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"destroy_photo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/favorites/:id(.:format)
 * @param {any} visit_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_favorite_url = __jsr.r({"visit_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"favorites"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/favorites/:id(.:format)
 * @param {any} visit_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_favorite_path = __jsr.r({"visit_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"favorites"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/favorites/:favorite_id/destroy_photo(.:format)
 * @param {any} visit_id
 * @param {any} favorite_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_favorite_destroy_photo_url = __jsr.r({"visit_id":{"r":true},"favorite_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"favorites"],[2,[7,"/"],[2,[3,"favorite_id"],[2,[7,"/"],[2,[6,"destroy_photo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/favorites/:favorite_id/destroy_photo(.:format)
 * @param {any} visit_id
 * @param {any} favorite_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_favorite_destroy_photo_path = __jsr.r({"visit_id":{"r":true},"favorite_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"favorites"],[2,[7,"/"],[2,[3,"favorite_id"],[2,[7,"/"],[2,[6,"destroy_photo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/favorites/:favorite_id/select_tree(.:format)
 * @param {any} visit_id
 * @param {any} favorite_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_favorite_select_tree_url = __jsr.r({"visit_id":{"r":true},"favorite_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"favorites"],[2,[7,"/"],[2,[3,"favorite_id"],[2,[7,"/"],[2,[6,"select_tree"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/favorites/:favorite_id/select_tree(.:format)
 * @param {any} visit_id
 * @param {any} favorite_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_favorite_select_tree_path = __jsr.r({"visit_id":{"r":true},"favorite_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"favorites"],[2,[7,"/"],[2,[3,"favorite_id"],[2,[7,"/"],[2,[6,"select_tree"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/favorites/:favorite_id/transfer_tree(.:format)
 * @param {any} visit_id
 * @param {any} favorite_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_favorite_transfer_tree_url = __jsr.r({"visit_id":{"r":true},"favorite_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"favorites"],[2,[7,"/"],[2,[3,"favorite_id"],[2,[7,"/"],[2,[6,"transfer_tree"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/favorites/:favorite_id/transfer_tree(.:format)
 * @param {any} visit_id
 * @param {any} favorite_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_favorite_transfer_tree_path = __jsr.r({"visit_id":{"r":true},"favorite_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"favorites"],[2,[7,"/"],[2,[3,"favorite_id"],[2,[7,"/"],[2,[6,"transfer_tree"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/favorites/:favorite_id/upload_photos(.:format)
 * @param {any} visit_id
 * @param {any} favorite_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_favorite_upload_photos_url = __jsr.r({"visit_id":{"r":true},"favorite_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"favorites"],[2,[7,"/"],[2,[3,"favorite_id"],[2,[7,"/"],[2,[6,"upload_photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/favorites/:favorite_id/upload_photos(.:format)
 * @param {any} visit_id
 * @param {any} favorite_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_favorite_upload_photos_path = __jsr.r({"visit_id":{"r":true},"favorite_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"favorites"],[2,[7,"/"],[2,[3,"favorite_id"],[2,[7,"/"],[2,[6,"upload_photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/favorites(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_favorites_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"favorites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/favorites(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_favorites_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"favorites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/recommendations/:id(.:format)
 * @param {any} visit_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_recommendation_url = __jsr.r({"visit_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"recommendations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/recommendations/:id(.:format)
 * @param {any} visit_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_recommendation_path = __jsr.r({"visit_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"recommendations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/recommendations(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_recommendations_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"recommendations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/recommendations(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_recommendations_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"recommendations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/reset(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_reset_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"reset"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/reset(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_reset_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"reset"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/select_tree(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_select_tree_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"select_tree"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/select_tree(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_select_tree_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"select_tree"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/send_email_link_to_customer(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_send_email_link_to_customer_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"send_email_link_to_customer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/send_email_link_to_customer(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_send_email_link_to_customer_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"send_email_link_to_customer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/transfer_trees_suggestions(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_transfer_trees_suggestions_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"transfer_trees_suggestions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/transfer_trees_suggestions(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_transfer_trees_suggestions_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"transfer_trees_suggestions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/transfers(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_transfers_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/transfers(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_transfers_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/trees(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_trees_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"trees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/trees(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_trees_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"trees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/update_channel(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_update_channel_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"update_channel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/update_channel(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visit_update_channel_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"update_channel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visits_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_visits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/wishes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_wishes_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"wishes"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /internal/wishes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const internal_wishes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"wishes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/customers/lookup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lookup_internal_customers_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"lookup"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/customers/lookup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lookup_internal_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"lookup"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/salesforce/order_roles_validator/lookup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lookup_internal_salesforce_order_roles_validator_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"order_roles_validator"],[2,[7,"/"],[2,[6,"lookup"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/salesforce/order_roles_validator/lookup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lookup_internal_salesforce_order_roles_validator_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"order_roles_validator"],[2,[7,"/"],[2,[6,"lookup"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/lookup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lookup_internal_visits_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[6,"lookup"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/lookup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lookup_internal_visits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[6,"lookup"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /shop/map/trees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const map_tree_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shop"],[2,[7,"/"],[2,[6,"map"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /shop/map/trees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const map_tree_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shop"],[2,[7,"/"],[2,[6,"map"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /meet_my_tree/:url_slug(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const meet_my_tree_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"meet_my_tree"],[2,[7,"/"],[2,[3,"url_slug"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /meet_my_tree/:url_slug(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const meet_my_tree_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"meet_my_tree"],[2,[7,"/"],[2,[3,"url_slug"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /meet_my_tree/:url_slug/share(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const meet_my_tree_share_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"meet_my_tree"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"share"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /meet_my_tree/:url_slug/share(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const meet_my_tree_share_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"meet_my_tree"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"share"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /spreadings/:sfdc_honoree_id/spreading_affidavits/:id(.:format)
 * @param {any} sfdc_honoree_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const memorial_ceremonies_spreading_affidavit_url = __jsr.r({"sfdc_honoree_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[3,"sfdc_honoree_id"],[2,[7,"/"],[2,[6,"spreading_affidavits"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /spreadings/:sfdc_honoree_id/spreading_affidavits/:id(.:format)
 * @param {any} sfdc_honoree_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const memorial_ceremonies_spreading_affidavit_path = __jsr.r({"sfdc_honoree_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[3,"sfdc_honoree_id"],[2,[7,"/"],[2,[6,"spreading_affidavits"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /spreadings/:sfdc_honoree_id/spreading_affidavits(.:format)
 * @param {any} sfdc_honoree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const memorial_ceremonies_spreading_affidavits_url = __jsr.r({"sfdc_honoree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[3,"sfdc_honoree_id"],[2,[7,"/"],[2,[6,"spreading_affidavits"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /spreadings/:sfdc_honoree_id/spreading_affidavits(.:format)
 * @param {any} sfdc_honoree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const memorial_ceremonies_spreading_affidavits_path = __jsr.r({"sfdc_honoree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[3,"sfdc_honoree_id"],[2,[7,"/"],[2,[6,"spreading_affidavits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/api/salesforce/users/merge(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const merge_internal_api_salesforce_users_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"merge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/api/salesforce/users/merge(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const merge_internal_api_salesforce_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"merge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payment_method/monthly_charge_note(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const monthly_charge_note_dashboard_order_payment_method_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payment_method"],[2,[7,"/"],[2,[6,"monthly_charge_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payment_method/monthly_charge_note(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const monthly_charge_note_dashboard_order_payment_method_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payment_method"],[2,[7,"/"],[2,[6,"monthly_charge_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/amendments/:amendment_id/payments/new(.:format)
 * @param {any} url_slug
 * @param {any} amendment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_amendment_order_payment_url = __jsr.r({"url_slug":{"r":true},"amendment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"amendments"],[2,[7,"/"],[2,[3,"amendment_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/amendments/:amendment_id/payments/new(.:format)
 * @param {any} url_slug
 * @param {any} amendment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_amendment_order_payment_path = __jsr.r({"url_slug":{"r":true},"amendment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"amendments"],[2,[7,"/"],[2,[3,"amendment_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/at_need_costs/:at_need_cost_id/payments/new(.:format)
 * @param {any} url_slug
 * @param {any} at_need_cost_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_at_need_cost_order_payment_url = __jsr.r({"url_slug":{"r":true},"at_need_cost_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/at_need_costs/:at_need_cost_id/payments/new(.:format)
 * @param {any} url_slug
 * @param {any} at_need_cost_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_at_need_cost_order_payment_path = __jsr.r({"url_slug":{"r":true},"at_need_cost_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_customer_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /customers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_customer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/forest_memorials/:forest_memorial_id/ceremony_readings/new(.:format)
 * @param {any} forest_memorial_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_dashboard_forest_memorial_ceremony_reading_url = __jsr.r({"forest_memorial_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"forest_memorials"],[2,[7,"/"],[2,[3,"forest_memorial_id"],[2,[7,"/"],[2,[6,"ceremony_readings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/forest_memorials/:forest_memorial_id/ceremony_readings/new(.:format)
 * @param {any} forest_memorial_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_dashboard_forest_memorial_ceremony_reading_path = __jsr.r({"forest_memorial_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"forest_memorials"],[2,[7,"/"],[2,[3,"forest_memorial_id"],[2,[7,"/"],[2,[6,"ceremony_readings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/meet_my_tree/share/new(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_dashboard_order_meet_my_tree_share_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"meet_my_tree"],[2,[7,"/"],[2,[6,"share"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/meet_my_tree/share/new(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_dashboard_order_meet_my_tree_share_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"meet_my_tree"],[2,[7,"/"],[2,[6,"share"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/customers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_customer_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/customers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_customer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/employees/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_employee_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/employees/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_employee_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_forest_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_forest_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/collections/new(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_forest_collection_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/collections/new(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_forest_collection_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"collections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/csv_uploader/new(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_forest_csv_uploader_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"csv_uploader"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/csv_uploader/new(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_forest_csv_uploader_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"csv_uploader"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_product_types/new(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_forest_forest_product_type_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_product_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_product_types/new(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_forest_forest_product_type_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_product_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/new(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_forest_forest_section_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/new(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_forest_forest_section_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/:forest_section_id/map_image/new(.:format)
 * @param {any} forest_id
 * @param {any} forest_section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_forest_forest_section_map_image_url = __jsr.r({"forest_id":{"r":true},"forest_section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[3,"forest_section_id"],[2,[7,"/"],[2,[6,"map_image"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/:forest_section_id/map_image/new(.:format)
 * @param {any} forest_id
 * @param {any} forest_section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_forest_forest_section_map_image_path = __jsr.r({"forest_id":{"r":true},"forest_section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[3,"forest_section_id"],[2,[7,"/"],[2,[6,"map_image"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/:forest_section_id/photos/new(.:format)
 * @param {any} forest_id
 * @param {any} forest_section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_forest_forest_section_photo_url = __jsr.r({"forest_id":{"r":true},"forest_section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[3,"forest_section_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/forest_sections/:forest_section_id/photos/new(.:format)
 * @param {any} forest_id
 * @param {any} forest_section_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_forest_forest_section_photo_path = __jsr.r({"forest_id":{"r":true},"forest_section_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"forest_sections"],[2,[7,"/"],[2,[3,"forest_section_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/photo_uploader/new(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_forest_photo_uploader_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"photo_uploader"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/photo_uploader/new(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_forest_photo_uploader_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"photo_uploader"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/pricing_schemas/new(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_forest_pricing_schema_url = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"pricing_schemas"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/forests/:forest_id/pricing_schemas/new(.:format)
 * @param {any} forest_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_forest_pricing_schema_path = __jsr.r({"forest_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"forests"],[2,[7,"/"],[2,[3,"forest_id"],[2,[7,"/"],[2,[6,"pricing_schemas"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/impact_trees_awards/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_impact_trees_award_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"impact_trees_awards"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/impact_trees_awards/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_impact_trees_award_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"impact_trees_awards"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_memorial_ceremony_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_memorial_ceremony_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:memorial_ceremony_id/spreadings/new(.:format)
 * @param {any} memorial_ceremony_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_memorial_ceremony_spreading_url = __jsr.r({"memorial_ceremony_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"memorial_ceremony_id"],[2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/memorial_ceremonies/:memorial_ceremony_id/spreadings/new(.:format)
 * @param {any} memorial_ceremony_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_memorial_ceremony_spreading_path = __jsr.r({"memorial_ceremony_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[3,"memorial_ceremony_id"],[2,[7,"/"],[2,[6,"spreadings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/amendments/new(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_order_amendment_url = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"amendments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/amendments/new(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_order_amendment_path = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"amendments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/at_need_costs/:at_need_cost_id/payments/new(.:format)
 * @param {any} order_id
 * @param {any} at_need_cost_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_order_at_need_cost_payment_url = __jsr.r({"order_id":{"r":true},"at_need_cost_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/at_need_costs/:at_need_cost_id/payments/new(.:format)
 * @param {any} order_id
 * @param {any} at_need_cost_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_order_at_need_cost_payment_path = __jsr.r({"order_id":{"r":true},"at_need_cost_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"at_need_costs"],[2,[7,"/"],[2,[3,"at_need_cost_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/maintenance_rules/new(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_order_maintenance_rule_url = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"maintenance_rules"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/maintenance_rules/new(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_order_maintenance_rule_path = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"maintenance_rules"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/payment_plans/new(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_order_payment_plan_url = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"payment_plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/payment_plans/new(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_order_payment_plan_path = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"payment_plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/payment_plans/:payment_plan_id/payments/new(.:format)
 * @param {any} order_id
 * @param {any} payment_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_order_payment_plan_payment_url = __jsr.r({"order_id":{"r":true},"payment_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"payment_plans"],[2,[7,"/"],[2,[3,"payment_plan_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/payment_plans/:payment_plan_id/payments/new(.:format)
 * @param {any} order_id
 * @param {any} payment_plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_order_payment_plan_payment_path = __jsr.r({"order_id":{"r":true},"payment_plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"payment_plans"],[2,[7,"/"],[2,[3,"payment_plan_id"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/terms_of_purchase/new(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_order_terms_of_purchase_url = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"terms_of_purchase"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/terms_of_purchase/new(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_order_terms_of_purchase_path = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"terms_of_purchase"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/transfers/new(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_order_transfer_url = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/transfers/new(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_order_transfer_path = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/promotions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_promotion_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"promotions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/promotions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_promotion_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"promotions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/salesforce/order_roles_validator/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_salesforce_order_roles_validator_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"order_roles_validator"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/salesforce/order_roles_validator/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_salesforce_order_roles_validator_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"order_roles_validator"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/species/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_species_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/species/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_species_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/species/:species_id/forest_species/new(.:format)
 * @param {any} species_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_species_forest_species_url = __jsr.r({"species_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"forest_species"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/species/:species_id/forest_species/new(.:format)
 * @param {any} species_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_species_forest_species_path = __jsr.r({"species_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"forest_species"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/species/:species_id/species_illustration/new(.:format)
 * @param {any} species_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_species_species_illustration_url = __jsr.r({"species_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"species_illustration"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/species/:species_id/species_illustration/new(.:format)
 * @param {any} species_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_species_species_illustration_path = __jsr.r({"species_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"species"],[2,[7,"/"],[2,[3,"species_id"],[2,[7,"/"],[2,[6,"species_illustration"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_tree_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_tree_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/tree_certificates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_tree_certificate_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tree_certificates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/tree_certificates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_tree_certificate_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"tree_certificates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/maintenance_rules/new(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_tree_maintenance_rule_url = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"maintenance_rules"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/maintenance_rules/new(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_tree_maintenance_rule_path = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"maintenance_rules"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/photos/new(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_tree_photo_url = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/photos/new(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_tree_photo_path = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/videos/new(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_tree_video_url = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"videos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/videos/new(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_tree_video_path = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"videos"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_visit_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_visit_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/favorites/new(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_visit_favorite_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"favorites"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/favorites/new(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_visit_favorite_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"favorites"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/recommendations/new(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_visit_recommendation_url = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"recommendations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/visits/:visit_id/recommendations/new(.:format)
 * @param {any} visit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_internal_visit_recommendation_path = __jsr.r({"visit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"visit_id"],[2,[7,"/"],[2,[6,"recommendations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /meet_my_tree/:url_slug/share/new(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_meet_my_tree_share_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"meet_my_tree"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"share"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /meet_my_tree/:url_slug/share/new(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_meet_my_tree_share_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"meet_my_tree"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"share"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shop/photos_uploader/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_photos_uploader_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shop"],[2,[7,"/"],[2,[6,"photos_uploader"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /shop/photos_uploader/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_photos_uploader_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shop"],[2,[7,"/"],[2,[6,"photos_uploader"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /invite-friends-and-family/:referral_code(.:format)
 * @param {any} referral_code
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_referral_url = __jsr.r({"referral_code":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invite-friends-and-family"],[2,[7,"/"],[2,[3,"referral_code"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /invite-friends-and-family/:referral_code(.:format)
 * @param {any} referral_code
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_referral_path = __jsr.r({"referral_code":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"invite-friends-and-family"],[2,[7,"/"],[2,[3,"referral_code"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /online-tour/welcome(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const online_tour_welcome_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"online-tour"],[2,[7,"/"],[2,[6,"welcome"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /online-tour/welcome(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const online_tour_welcome_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"online-tour"],[2,[7,"/"],[2,[6,"welcome"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /owners_authorizations/:url_slug/:id(.:format)
 * @param {any} url_slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owners_authorization_url = __jsr.r({"url_slug":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners_authorizations"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /owners_authorizations/:url_slug/:id(.:format)
 * @param {any} url_slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const owners_authorization_path = __jsr.r({"url_slug":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"owners_authorizations"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/ownership_transfers/:ownership_transfer_id/transfer_of_ownership_agreements/:signature_uuid(.:format)
 * @param {any} url_slug
 * @param {any} ownership_transfer_id
 * @param {any} signature_uuid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ownership_transfer_transfer_of_ownership_agreement_url = __jsr.r({"url_slug":{"r":true},"ownership_transfer_id":{"r":true},"signature_uuid":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"ownership_transfers"],[2,[7,"/"],[2,[3,"ownership_transfer_id"],[2,[7,"/"],[2,[6,"transfer_of_ownership_agreements"],[2,[7,"/"],[2,[3,"signature_uuid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/ownership_transfers/:ownership_transfer_id/transfer_of_ownership_agreements/:signature_uuid(.:format)
 * @param {any} url_slug
 * @param {any} ownership_transfer_id
 * @param {any} signature_uuid
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ownership_transfer_transfer_of_ownership_agreement_path = __jsr.r({"url_slug":{"r":true},"ownership_transfer_id":{"r":true},"signature_uuid":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"ownership_transfers"],[2,[7,"/"],[2,[3,"ownership_transfer_id"],[2,[7,"/"],[2,[6,"transfer_of_ownership_agreements"],[2,[7,"/"],[2,[3,"signature_uuid"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/registration/password_step(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const password_step_user_registration_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"registration"],[2,[7,"/"],[2,[6,"password_step"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/registration/password_step(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const password_step_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"registration"],[2,[7,"/"],[2,[6,"password_step"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payments/new/payment_amount(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payment_amount_new_dashboard_order_payment_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"new"],[2,[7,"/"],[2,[6,"payment_amount"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payments/new/payment_amount(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payment_amount_new_dashboard_order_payment_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"new"],[2,[7,"/"],[2,[6,"payment_amount"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payments/payment_button(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payment_button_dashboard_order_payments_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"payment_button"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payments/payment_button(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payment_button_dashboard_order_payments_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"payment_button"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/payment(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payment_checkout_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/payment(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payment_checkout_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/payment_method(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payment_method_checkout_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"payment_method"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/payment_method(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payment_method_checkout_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"payment_method"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payments/new/payment_method(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payment_method_new_dashboard_order_payment_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"new"],[2,[7,"/"],[2,[6,"payment_method"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payments/new/payment_method(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payment_method_new_dashboard_order_payment_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"new"],[2,[7,"/"],[2,[6,"payment_method"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /people/:person_sfdc_contact_id/testimonial_photo_likeness_releases/:id(.:format)
 * @param {any} person_sfdc_contact_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_testimonial_photo_likeness_release_url = __jsr.r({"person_sfdc_contact_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"person_sfdc_contact_id"],[2,[7,"/"],[2,[6,"testimonial_photo_likeness_releases"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /people/:person_sfdc_contact_id/testimonial_photo_likeness_releases/:id(.:format)
 * @param {any} person_sfdc_contact_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_testimonial_photo_likeness_release_path = __jsr.r({"person_sfdc_contact_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"person_sfdc_contact_id"],[2,[7,"/"],[2,[6,"testimonial_photo_likeness_releases"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /people/:person_sfdc_contact_id/testimonial_photo_likeness_releases/:testimonial_photo_likeness_release_id/thank_you(.:format)
 * @param {any} person_sfdc_contact_id
 * @param {any} testimonial_photo_likeness_release_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_testimonial_photo_likeness_release_thank_you_url = __jsr.r({"person_sfdc_contact_id":{"r":true},"testimonial_photo_likeness_release_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"person_sfdc_contact_id"],[2,[7,"/"],[2,[6,"testimonial_photo_likeness_releases"],[2,[7,"/"],[2,[3,"testimonial_photo_likeness_release_id"],[2,[7,"/"],[2,[6,"thank_you"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /people/:person_sfdc_contact_id/testimonial_photo_likeness_releases/:testimonial_photo_likeness_release_id/thank_you(.:format)
 * @param {any} person_sfdc_contact_id
 * @param {any} testimonial_photo_likeness_release_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_testimonial_photo_likeness_release_thank_you_path = __jsr.r({"person_sfdc_contact_id":{"r":true},"testimonial_photo_likeness_release_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"person_sfdc_contact_id"],[2,[7,"/"],[2,[6,"testimonial_photo_likeness_releases"],[2,[7,"/"],[2,[3,"testimonial_photo_likeness_release_id"],[2,[7,"/"],[2,[6,"thank_you"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /people/:person_sfdc_contact_id/testimonial_photo_likeness_releases(.:format)
 * @param {any} person_sfdc_contact_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_testimonial_photo_likeness_releases_url = __jsr.r({"person_sfdc_contact_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"person_sfdc_contact_id"],[2,[7,"/"],[2,[6,"testimonial_photo_likeness_releases"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /people/:person_sfdc_contact_id/testimonial_photo_likeness_releases(.:format)
 * @param {any} person_sfdc_contact_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_testimonial_photo_likeness_releases_path = __jsr.r({"person_sfdc_contact_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"person_sfdc_contact_id"],[2,[7,"/"],[2,[6,"testimonial_photo_likeness_releases"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /shop/pet_spreadings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pet_spreadings_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shop"],[2,[7,"/"],[2,[6,"pet_spreadings"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /shop/pet_spreadings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pet_spreadings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shop"],[2,[7,"/"],[2,[6,"pet_spreadings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/tree/photos(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const photos_dashboard_order_tree_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"tree"],[2,[7,"/"],[2,[6,"photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/tree/photos(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const photos_dashboard_order_tree_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"tree"],[2,[7,"/"],[2,[6,"photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shop/photos_uploader(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const photos_uploader_index_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shop"],[2,[7,"/"],[2,[6,"photos_uploader"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /shop/photos_uploader(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const photos_uploader_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"shop"],[2,[7,"/"],[2,[6,"photos_uploader"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/plans/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/plan_invites/:url_slug(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_invite_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"plan_invites"],[2,[7,"/"],[2,[3,"url_slug"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/plan_invites/:url_slug(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_invite_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"plan_invites"],[2,[7,"/"],[2,[3,"url_slug"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/plans/:plan_id/plan_contacts/:id(.:format)
 * @param {any} plan_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_plan_contact_url = __jsr.r({"plan_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"plan_contacts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/plans/:plan_id/plan_contacts/:id(.:format)
 * @param {any} plan_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_plan_contact_path = __jsr.r({"plan_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"plan_contacts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/plans/:plan_id/plan_contacts(.:format)
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_plan_contacts_url = __jsr.r({"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"plan_contacts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/plans/:plan_id/plan_contacts(.:format)
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_plan_contacts_path = __jsr.r({"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"plan_contacts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/plans/:plan_id/plan_facts(.:format)
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_plan_facts_url = __jsr.r({"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"plan_facts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/plans/:plan_id/plan_facts(.:format)
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_plan_facts_path = __jsr.r({"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"plan_facts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/plans/:plan_id/plan_invites(.:format)
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_plan_invites_url = __jsr.r({"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"plan_invites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/plans/:plan_id/plan_invites(.:format)
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_plan_invites_path = __jsr.r({"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"plan_invites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/plans/:plan_id/plan_roles/:id(.:format)
 * @param {any} plan_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_plan_role_url = __jsr.r({"plan_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"plan_roles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/plans/:plan_id/plan_roles/:id(.:format)
 * @param {any} plan_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plan_plan_role_path = __jsr.r({"plan_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[2,[7,"/"],[2,[6,"plan_roles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plans_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/plans(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const plans_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/postsignup_completion(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const postsignup_completion_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"postsignup_completion"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /api/v1/postsignup_completion(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const postsignup_completion_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"postsignup_completion"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /w/preamble(/*book)(/*url_slug)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preamble_url = __jsr.r({"book":{},"url_slug":{},"format":{}}, [2,[7,"/"],[2,[6,"w"],[2,[7,"/"],[2,[6,"preamble"],[2,[1,[2,[7,"/"],[5,[3,"book"]]]],[2,[1,[2,[7,"/"],[5,[3,"url_slug"]]]],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /w/preamble(/*book)(/*url_slug)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preamble_path = __jsr.r({"book":{},"url_slug":{},"format":{}}, [2,[7,"/"],[2,[6,"w"],[2,[7,"/"],[2,[6,"preamble"],[2,[1,[2,[7,"/"],[5,[3,"book"]]]],[2,[1,[2,[7,"/"],[5,[3,"url_slug"]]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_url = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_url = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_url = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_url = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_url = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_url = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/terms_of_purchase/regenerate(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const regenerate_internal_order_terms_of_purchase_url = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"terms_of_purchase"],[2,[7,"/"],[2,[6,"regenerate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/contracts/:order_id/terms_of_purchase/regenerate(.:format)
 * @param {any} order_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const regenerate_internal_order_terms_of_purchase_path = __jsr.r({"order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"order_id"],[2,[7,"/"],[2,[6,"terms_of_purchase"],[2,[7,"/"],[2,[6,"regenerate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_url = __jsr.r({}, [7,"/"], true);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payments/savings(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const savings_dashboard_order_payments_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"savings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/payments/savings(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const savings_dashboard_order_payments_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"payments"],[2,[7,"/"],[2,[6,"savings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/tree_holds/search(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_internal_tree_tree_holds_url = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"tree_holds"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/trees/:tree_id/tree_holds/search(.:format)
 * @param {any} tree_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_internal_tree_tree_holds_path = __jsr.r({"tree_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"trees"],[2,[7,"/"],[2,[3,"tree_id"],[2,[7,"/"],[2,[6,"tree_holds"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/payment_plan/select_and_continue_to_payment(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const select_and_continue_to_payment_checkout_payment_plan_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"payment_plan"],[2,[7,"/"],[2,[6,"select_and_continue_to_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/payment_plan/select_and_continue_to_payment(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const select_and_continue_to_payment_checkout_payment_plan_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"payment_plan"],[2,[7,"/"],[2,[6,"select_and_continue_to_payment"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/carts/:id/send_checkout_email_to_customer(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_checkout_email_to_customer_internal_cart_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"carts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_checkout_email_to_customer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/carts/:id/send_checkout_email_to_customer(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_checkout_email_to_customer_internal_cart_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"carts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_checkout_email_to_customer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /w/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"w"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /w/settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"w"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /r/:source/:channel/:code(.:format)
 * @param {any} source
 * @param {any} channel
 * @param {any} code
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shortened_referral_url = __jsr.r({"source":{"r":true},"channel":{"r":true},"code":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"r"],[2,[7,"/"],[2,[3,"source"],[2,[7,"/"],[2,[3,"channel"],[2,[7,"/"],[2,[3,"code"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /r/:source/:channel/:code(.:format)
 * @param {any} source
 * @param {any} channel
 * @param {any} code
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shortened_referral_path = __jsr.r({"source":{"r":true},"channel":{"r":true},"code":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"r"],[2,[7,"/"],[2,[3,"source"],[2,[7,"/"],[2,[3,"channel"],[2,[7,"/"],[2,[3,"code"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /:short_code(.:format)
 * @param {any} short_code
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shortlink_url = __jsr.r({"short_code":{"r":true},"format":{},"subdomain":{"d":"short"}}, [2,[7,"/"],[2,[3,"short_code"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /:short_code(.:format)
 * @param {any} short_code
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shortlink_path = __jsr.r({"short_code":{"r":true},"format":{},"subdomain":{"d":"short"}}, [2,[7,"/"],[2,[3,"short_code"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/registration/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sign_up_user_registration_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"registration"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/registration/sign_up(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sign_up_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"registration"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /visits/:url_slug/site_liability_waivers/:id(.:format)
 * @param {any} url_slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const site_liability_waiver_url = __jsr.r({"url_slug":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"site_liability_waivers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /visits/:url_slug/site_liability_waivers/:id(.:format)
 * @param {any} url_slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const site_liability_waiver_path = __jsr.r({"url_slug":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"site_liability_waivers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /visits/:url_slug/site_liability_waivers(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const site_liability_waivers_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"site_liability_waivers"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /visits/:url_slug/site_liability_waivers(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const site_liability_waivers_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"site_liability_waivers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /stop_ghost(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stop_ghost_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"stop_ghost"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /stop_ghost(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const stop_ghost_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"stop_ghost"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/registration/store_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const store_email_user_registration_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"registration"],[2,[7,"/"],[2,[6,"store_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/registration/store_email(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const store_email_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"registration"],[2,[7,"/"],[2,[6,"store_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /checkouts/:url_slug/terms_of_purchases/:id(.:format)
 * @param {any} url_slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const terms_of_purchase_url = __jsr.r({"url_slug":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"checkouts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"terms_of_purchases"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /checkouts/:url_slug/terms_of_purchases/:id(.:format)
 * @param {any} url_slug
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const terms_of_purchase_path = __jsr.r({"url_slug":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"checkouts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"terms_of_purchases"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /checkouts/:url_slug/terms_of_purchases(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const terms_of_purchases_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"checkouts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"terms_of_purchases"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /checkouts/:url_slug/terms_of_purchases(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const terms_of_purchases_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"checkouts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"terms_of_purchases"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /typeform_surveys/:tool_slug(.:format)
 * @param {any} tool_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const typeform_survey_url = __jsr.r({"tool_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"typeform_surveys"],[2,[7,"/"],[2,[3,"tool_slug"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /typeform_surveys/:tool_slug(.:format)
 * @param {any} tool_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const typeform_survey_path = __jsr.r({"tool_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"typeform_surveys"],[2,[7,"/"],[2,[3,"tool_slug"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /internal/carts/:id/unlock_tree(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unlock_tree_internal_cart_url = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"carts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unlock_tree"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/carts/:id/unlock_tree(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unlock_tree_internal_cart_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"carts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unlock_tree"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/api/salesforce/memorial_ceremonies/update_crm_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_crm_info_internal_api_salesforce_memorial_ceremonies_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[6,"update_crm_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/api/salesforce/memorial_ceremonies/update_crm_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_crm_info_internal_api_salesforce_memorial_ceremonies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"memorial_ceremonies"],[2,[7,"/"],[2,[6,"update_crm_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/api/salesforce/users/update_crm_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_crm_info_internal_api_salesforce_users_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"update_crm_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/api/salesforce/users/update_crm_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_crm_info_internal_api_salesforce_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"update_crm_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /internal/api/salesforce/visits/update_crm_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_crm_info_internal_api_salesforce_visits_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[6,"update_crm_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /internal/api/salesforce/visits/update_crm_info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_crm_info_internal_api_salesforce_visits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"internal"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"salesforce"],[2,[7,"/"],[2,[6,"visits"],[2,[7,"/"],[2,[6,"update_crm_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/payment_plan/update_method(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_method_checkout_payment_plan_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"payment_plan"],[2,[7,"/"],[2,[6,"update_method"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /contracts/:url_slug/checkout/payment_plan/update_method(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_method_checkout_payment_plan_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"payment_plan"],[2,[7,"/"],[2,[6,"update_method"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customers/update_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_password_customers_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"update_password"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /customers/update_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_password_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"update_password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_url = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/facebook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_facebook_omniauth_authorize_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"facebook"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/auth/facebook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_facebook_omniauth_authorize_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"facebook"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/facebook/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_facebook_omniauth_callback_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"facebook"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/auth/facebook/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_facebook_omniauth_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"facebook"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/google_oauth2(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_google_oauth2_omniauth_authorize_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"google_oauth2"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/auth/google_oauth2(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_google_oauth2_omniauth_authorize_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"google_oauth2"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/google_oauth2/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_google_oauth2_omniauth_callback_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"google_oauth2"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/auth/google_oauth2/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_google_oauth2_omniauth_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"google_oauth2"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/registration/user_identified_step(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_identified_step_user_registration_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"registration"],[2,[7,"/"],[2,[6,"user_identified_step"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/registration/user_identified_step(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_identified_step_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"registration"],[2,[7,"/"],[2,[6,"user_identified_step"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/resend-verification(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_resend_verification_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"resend-verification"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/resend-verification(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_resend_verification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"resend-verification"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_root_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]], true);

/**
 * Generates rails route to
 * /user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/verify/:email_verification_token(.:format)
 * @param {any} email_verification_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_verify_email_url = __jsr.r({"email_verification_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"verify"],[2,[7,"/"],[2,[3,"email_verification_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/verify/:email_verification_token(.:format)
 * @param {any} email_verification_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_verify_email_path = __jsr.r({"email_verification_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"verify"],[2,[7,"/"],[2,[3,"email_verification_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/passwords/reset_success(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_passwords_reset_success_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"reset_success"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true);

/**
 * Generates rails route to
 * /users/passwords/reset_success(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_passwords_reset_success_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"reset_success"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/tree/visit_photos(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const visit_photos_dashboard_order_tree_url = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"tree"],[2,[7,"/"],[2,[6,"visit_photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /dashboard/contracts/:url_slug/tree/visit_photos(.:format)
 * @param {any} url_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const visit_photos_dashboard_order_tree_path = __jsr.r({"url_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"url_slug"],[2,[7,"/"],[2,[6,"tree"],[2,[7,"/"],[2,[6,"visit_photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /w(/*wishes_routes)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wishes_url = __jsr.r({"wishes_routes":{},"format":{}}, [2,[7,"/"],[2,[6,"w"],[2,[1,[2,[7,"/"],[5,[3,"wishes_routes"]]]],[1,[2,[8,"."],[3,"format"]]]]]], true);

/**
 * Generates rails route to
 * /w(/*wishes_routes)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wishes_path = __jsr.r({"wishes_routes":{},"format":{}}, [2,[7,"/"],[2,[6,"w"],[2,[1,[2,[7,"/"],[5,[3,"wishes_routes"]]]],[1,[2,[8,"."],[3,"format"]]]]]]);

/**
 * Generates rails route to
 * /w/postsignup(/*signup_routes)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wishes_postsignup_url = __jsr.r({"signup_routes":{},"format":{}}, [2,[7,"/"],[2,[6,"w"],[2,[7,"/"],[2,[6,"postsignup"],[2,[1,[2,[7,"/"],[5,[3,"signup_routes"]]]],[1,[2,[8,"."],[3,"format"]]]]]]]], true);

/**
 * Generates rails route to
 * /w/postsignup(/*signup_routes)(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wishes_postsignup_path = __jsr.r({"signup_routes":{},"format":{}}, [2,[7,"/"],[2,[6,"w"],[2,[7,"/"],[2,[6,"postsignup"],[2,[1,[2,[7,"/"],[5,[3,"signup_routes"]]]],[1,[2,[8,"."],[3,"format"]]]]]]]]);

/**
 * Generates rails route to
 * /w/shared/plans/:plan_id(.:format)
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wishes_shared_plan_url = __jsr.r({"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"w"],[2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true);

/**
 * Generates rails route to
 * /w/shared/plans/:plan_id(.:format)
 * @param {any} plan_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wishes_shared_plan_path = __jsr.r({"plan_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"w"],[2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"plan_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /w/terms_of_service(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wishes_terms_of_service_url = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"w"],[2,[7,"/"],[2,[6,"terms_of_service"],[1,[2,[8,"."],[3,"format"]]]]]]], true);

/**
 * Generates rails route to
 * /w/terms_of_service(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wishes_terms_of_service_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"w"],[2,[7,"/"],[2,[6,"terms_of_service"],[1,[2,[8,"."],[3,"format"]]]]]]]);


